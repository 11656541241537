.header-page {
	height: 56px;
	padding: 16px 14px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #fff;
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06);
	
	&__title {
		display: flex;
		align-items: center;
	}
	
	&__name {
		margin-left: 18px;
		font-family: 'TTCommons', sans-serif;
		font-size: 21px;
		font-weight: 600;
		color: #2c2d2e;
		padding-top: 6px;
	}
	
	&__type {
		font-family: 'SFProText', sans-serif;
		font-size: 15px;
		line-height: 1.33;
		letter-spacing: -0.24px;
		color: #397ecc;
		text-decoration: none;
	}

	.icon-back {
		background: url('../img/icons/back_24.svg') center no-repeat;
	}
}