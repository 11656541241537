.footer-download {
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #fff;
	padding: 12px 16px;

	.btn {
		width: calc(50% - 10px - 23px);
		padding-left: 5px;
		padding-right: 5px;
	}

	& > .edit-icon {
		width: 46px;
		height: 36px;
		background-image: url("../img/icons/edit_24.svg");
		background-size: 16.5px;
		background-repeat: no-repeat;
		background-position: center;
	}

	.btn + .btn {
		margin-left: 10px;
	}
}