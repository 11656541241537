.ColorPickerModal {
  z-index: 50;
  width: 100%;
  top: 0;
}

.ColorPicker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 343px;
  width: calc(100% - 32px);
  border-radius: 12px;
  box-shadow: 0 -1px 16px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  z-index: 1;
}

.ColorPicker__header {
  padding: 12px 4px;
  font-size: 13px;
  letter-spacing: -0.1px;
  color: #000;
  display: flex;
  font-family: 'SFProText', sans-serif;
}

.ColorPicker__content {
  padding: 7px 16px;
}

.ColorPicker__content_panel {
  display: none;
}

.ColorPicker__content_panel--selected {
  display: block;
}

.ColorPicker__content.alone {
  padding-top: 16px;
}

.ColorPicker__footer {
  padding: 9px 16px 16px;
  display: flex;
}

.ColorPicker__footer .btn + .btn {
  margin-left: 8px;
}

.ColorPicker__footer .btn {
  flex: 1;
}

.ColorPicker__title {
  font-size: 15px;
  letter-spacing: -0.24px;
  text-align: center;
  color: #99a2ad;
  height: 28px;
  line-height: 28px;
  cursor: pointer;
  flex: 1;
  position: relative;
}

.ColorPicker__title--active {
  color: #000;
  font-weight: 600;
}

.ColorPicker__title--active:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -7px;
  margin: 0 auto;
  width: 58px;
  height: 2px;
  border-radius: 2px;
  background: #3f8ae0;
  pointer-events: none;
}

.ColorPicker__title--selected::after {
  content: '';
  position: absolute;
  bottom: -8px;
  width: 100%;
  left: 0;
  height: 2px;
  background-color: #5181b8;
}

.chrome-picker {
  width: 100% !important;
  box-shadow: none !important;
}

.chrome-picker > div:first-child {
  border-radius: 8px !important;
  padding-bottom: initial !important;
  height: 140px;
}

.chrome-picker > div:last-child {
  padding: 16px 0 0 !important;
  position: relative;
}

.chrome-picker > div:last-child > div:first-child > div:first-child {
  position: absolute;
  left: 9px;
  bottom: 8px;
  width: 24px !important;
  height: 24px;
  border-radius: 4px;
}

.chrome-picker > div:last-child > div:first-child > div:first-child > div {
  width: 24px !important;
  height: 24px !important;
  border-radius: 4px !important;
}

.chrome-picker > div:last-child > div:first-child > div:first-child > div > div:first-child {
  border-radius: 4px !important;
  box-shadow: none !important;
  border: solid 0.5px rgba(0, 0, 0, 0.08);
}

.chrome-picker > div:last-child > div:first-child > div:last-child {
  height: 16px;
  border-radius: 8px;
  overflow: hidden;
}

.chrome-picker > div:last-child > div:first-child > div:last-child > div:first-child {
  height: 100% !important;
}

.chrome-picker > div:last-child > div:first-child > div:last-child > div:first-child > div > div > div > div {
  width: 16px !important;
  height: 16px !important;
  border-radius: 8px !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24) !important;
  border: solid 2px #ffffff !important;
  background-color: transparent !important;
  transform: translate(-6px, 0px) !important;
}

.chrome-picker > div:last-child > div:last-child input {
  height: 40px !important;
  border-radius: 8px !important;
  border: solid 1px #c4c8cc !important;
  box-shadow: none !important;
  outline: none;
  font-family: SFProText;
  font-size: 15px !important;
  line-height: 1.33;
  letter-spacing: -0.24px;
  color: #000000;
  text-align: left !important;
  padding-left: 44px;
}

.chrome-picker > div .flexbox-fix:last-child span {
  display: none !important;
}

.chrome-picker > div .flexbox-fix:last-child > div:last-child {
  display: none !important;
}
