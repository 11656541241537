@font-face { font-family: "TTCommons"; font-weight: 400; 	font-style: normal; src: url("../fonts/TT Commons Medium.otf"); }
@font-face { font-family: "TTCommons"; font-weight: 600; 	font-style: normal; src: url("../fonts/TT Commons DemiBold.ttf"); }
@font-face { font-family: "SFProText"; font-weight: 400;	font-style: normal;	src: url("../fonts/SFProText-Regular.ttf"); }
@font-face { font-family: "SFProText"; font-weight: 600;	font-style: normal;	src: url("../fonts/SFProText-Medium.ttf"); }

body {
	padding: 0;
	background: #edeef0;
	font-family: 'SFProText', -apple-system, 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', 'Geneva', 'Noto Sans Armenian', 'Noto Sans Bengali', 'Noto Sans Cherokee', 'Noto Sans Devanagari', 'Noto Sans Ethiopic', 'Noto Sans Georgian', 'Noto Sans Hebrew', 'Noto Sans Kannada', 'Noto Sans Khmer', 'Noto Sans Lao', 'Noto Sans Osmanya', 'Noto Sans Tamil', 'Noto Sans Telugu', 'Noto Sans Thai', sans-serif;
	margin: 0 auto;
}

*, *:before, *:after {
	box-sizing: border-box;
}

.qr-box {
	display: flex;
	justify-content: center;
	padding-bottom: 12px;

	& > div > span > svg {
		border-radius: 12px;
		box-shadow: 0 4px 26px 0 rgba(0, 0, 0, 0.12);
		background-color: #fff;
		margin-bottom: -15px;
	}

	& > div {
		transform: scale(0.88);
	}
}

.group-box {
	display: flex;
}

.flex-direction-column {
	flex-direction: column;
}

.mt-0 {
	margin-top: 0!important;
}

.mb-0 {
	margin-bottom: 0!important;
}

.mb-5 {
	margin-bottom: 5px!important;
}

.mb-15 {
	margin-bottom: 15px;
}

.no-border {

	&:after {
		display: none;
	}
}

.hide-it {
	display: none;
}

.modal-opened {
	overflow: hidden;
}

.full-height {
	min-height: calc(100vh - 126px);
}


.white-block .geo {
	border-radius: 4px;
	border: 1px solid rgba(#000, .04);
	margin-top: 25px;
	background: #eaedf0 url("../img/map.png") center no-repeat;
	background-size: contain;
	box-sizing: border-box;
	overflow: hidden;
	height: 240px;

	& > div {
		width: 100% !important;
	}
}

.ymaps-2-1-74-search_layout_panel.ymaps-2-1-74-search_layout_panel {
	//transform: translateY(0px)!important;
	box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.ymaps-2-1-74-searchbox__panel-layout .ymaps-2-1-74-searchbox__fold-button-cell {
	//display: none;
}

.ymaps-2-1-74-searchbox__normal-layout {
	width: calc(100% - 10px)!important;
}

.two {
	display: flex;

	& > .form-input {
		margin-top: 23px!important;
		max-width: calc(50% - 4.5px);
		width: 100%;
		position: relative;

		&:after {
			content: '';
			position: absolute;
			bottom: 16px;
			right: 12px;
			width: 11px;
			height: 6px;
			background: url("../img/icons/arrow.svg") no-repeat;
		}
	}

	.form-input + .form-input {
		margin-left: 9px;
	}
}

.Quote {

	& + .Quote {
		margin-top: 32px;
	}

	p:last-child {
		margin: 0;
	}

	&__title {
		font-size: 21px;
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
		line-height: 0.86;
		letter-spacing: normal;
		color: #2c2d2e;
		font-family: TTCommons;
	}

	&__text {
		font-size: 15px;
		line-height: 1.47;
		letter-spacing: -0.24px;
		color: #000000;
		margin-top: 10px;
	}
}

input, textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.date-error {
	display: none;
	font-size: 12.5px;
	letter-spacing: -0.08px;
	color: #e64646;
	margin-top: 9px;
}

.show-start-date-error  {

	.date-start input {
		border: solid 0.5px #ff5c5c;
		background-color: #faebeb;
	}

	.date-start-error {
		display: block;
	}
}

.show-end-date-error  {

	.date-end input {
		border: solid 0.5px #ff5c5c;
		background-color: #faebeb;
	}

	.date-end-error {
		display: block;
	}
}

.w-100 {
	width: 100%!important;
}

.hide-vis {
	visibility: hidden;
	position: absolute;
	top: 0;
	pointer-events: none;
}

@import "header";
@import "btn";
@import "main-menu";
@import "header-page";
@import "white-block";
@import "form-input";
// @import "form-checkbox";
@import "footer-btn";
@import "footer-download";
@import "tab-type";
@import "group-form";
@import "form-radio-color";
@import "form-add-img";
@import "form-radio";
@import "form-radio-icon";
@import "form-toggle";
@import "form-textarea";
@import "feedback-block";
@import "input-select";

@import "modal";
