.form-input {
	margin-top: 23px;
	font-family: 'SFProText', sans-serif;
	
	&__name {
		font-size: 15px;
		line-height: 1.33;
		letter-spacing: -0.24px;
		color: #909499;
		margin-bottom: 7px;
		
		span {
			color: #828282;
		}
	}
	
	> span {
		font-size: 15px;
		letter-spacing: -0.24px;
		color: #909499;
		display: block;
		margin-top: 9px;
	}
	
	input {
		width: 100%;
		padding: 12px 10px;
		border-radius: 8px;
		border: 1px solid #c4c8cc;
		height: 40px;
		background-color: #fff;
		outline: none;
		font-size: 15px;
		
		&::placeholder {
			font-size: 15px;
			letter-spacing: -0.24px;
			color: #909499;
		}
	}
	
	&__hint {
		margin-top: 10px;
		font-size: 12.5px;
		letter-spacing: -0.1px;
		color: #828282;
	}

	&.with-placeholder {
		position: relative;

		.input-placeholder {
			position: absolute;
			bottom: 10px;
			left: 10px;
			font-size: 15px;
			color: #909499;
			pointer-events: none;
		}
	}
}