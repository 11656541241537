.main-menu {
	font-family: 'SFProText', sans-serif;

	&__item {
		display: flex;
		background: #fff;
		padding: 15px 16px 0;
		
		&:last-child {
			padding-bottom: 16px;
			
			.main-menu__inner {
				border: none;
				padding-bottom: 0;
			}
		}
	}
	
	&__inner {
		border-bottom: 1px solid #e7e8ec;
		display: flex;
		flex-direction: column;
		padding-bottom: 16px;
		text-decoration: none;
		width: 100%;
	}
	
	&__link {
		display: flex;
		align-items: center;
		margin-bottom: 4px;
	}
	
	&__name {
		font-size: 16px;
		font-weight: 600;
		color: #2c2d2e;
		letter-spacing: -0.32px;
		margin-left: 16px;
	}
	
	&__info {
		font-size: 14px;
		line-height: 1.3;
		letter-spacing: -0.15px;
		color: #909499;
		margin-left: 40px;
	}
}

.icon-menu {
	background-position: center;
	background-repeat: no-repeat;
	background-size: 19px;
	width: 24px;
	height: 24px;

	&.link {
		background-image: url("../img/icons/link_blue.svg");
	}

	&.wifi {
		background-image: url("../img/icons/wifi_blue.svg");
	}

	&.contact {
		background-image: url("../img/icons/card_blue.svg");
	}

	&.text {
		background-image: url("../img/icons/text_blue.svg");
	}

	&.event {
		background-image: url("../img/icons/event_blue.svg");
	}

	&.geo {
		background-image: url("../img/icons/geo_blue.svg");
	}
}