.feedback-block {
    background: #fff;
    padding: 20px 16px 16px;
    margin-top: 1px;

    .form-input__name {
        margin-bottom: 5px;
    }

    .form-textarea {
        margin-top: 26px;
    }

    .feedback-btn .btn {
        height: 44px;
        font-size: 17px;
    }

    .form-textarea .textarea-error {
        border-color: #e64646;
        background-color: rgba(#e64646, 0.1);
    }

    .textarea-desc-error {
        color: #e64646;
        font-size: 14px;
        display: none;

        &.show {
            display: block;
        }
    }
}