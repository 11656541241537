.Input-select {
  position: relative;
}

.Input-select__input-block {
  position: relative;
}

.Input-select__input-block input {
  cursor: pointer;
}

.Input-select__input-block input:focus {
  cursor: text;
  border-radius: 4px 4px 0 0;
}

.Input-select__input-block_icon {
  background: url("../img/icons/arrow.svg") no-repeat;
  position: absolute;
  width: 11px;
  height: 6px;
  right: 10px;
  top: 18px;
}

.Input-select__list {
  display: none;
  font-size: 13px;
  letter-spacing: -0.1px;
  color: #000000;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 1px 1px 0 rgba(43, 47, 51, 0.17);
  border: solid 1px #d3d9de;
  background-color: #ffffff;
  border-top: none;
  position: absolute;
  z-index: 2;
  right: 0;
  left: 0;
  top: 40px;
}

.Input-select__list--open {
  display: block;
}

.Input-select__list_option {
  padding: 8px 9px 9px 9px;
  cursor: pointer;
  font-size: 15px;
}

.Input-select__list_option:hover {
  background-color: #e7edf2;
}
