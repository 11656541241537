.form-toggle {
	margin-top: 25px;
	font-family: 'SFProText', sans-serif;
	
	input[type="checkbox"] {
		display: none;
		
		& + label:before {
			content: '';
			display: block;
			width: 36px;
			height: 12px;
			border-radius: 12px;
			position: absolute;
			top: 3px;
			right: 1px;
			background: #e1e3e6;
		}
		
		& + label:after {
			content: ' ';
			display: block;
			height: 20px;
			width: 20px;
			border-radius: 50%;
			position: absolute;
			top: -1px;
			right: 17px;
			background: #ffffff;
			border: 1px solid #b8c1cc;
		}

		&:checked + label::before {
			background: #3f8ae0;
			opacity: 0.35;
		}
		
		&:checked + label::after {
			right: 0;
			background: #3f8ae0;
			border: none;
		}
	}
	
	label {
		position: relative;
		font-size: 15px;
		line-height: 1.33;
		letter-spacing: -0.24px;
		color: #000000;
		display: block;
	}
}