.form-textarea {
    margin-top: 25px;

    &__name{
        font-size: 15px;
        line-height: 1.33;
        letter-spacing: -0.24px;
        color: #909499;
    }

    textarea {
        font-family: 'SFProText', sans-serif;
        width: 100%;
        resize: none;
        outline: none;
        border-radius: 8px;
        border: 1px solid #c4c8cc;
        font-size: 15px;
        margin-top: 7px;
        background-color: #fff;
        padding: 12px 10px;
        letter-spacing: -0.1px;

        &::placeholder {
            font-family: 'SFProText', sans-serif;
            font-size: 15px;
            letter-spacing: -0.24px;
            color: #909499;
        }
    }
}
