.group-form {
	margin-top: 25px;
	font-family: 'SFProText', sans-serif;

	&__name {
		font-size: 15px;
		line-height: 1.33;
		letter-spacing: -0.24px;
		color: #909499;
		margin-bottom: 7px;
	}

	.form-input {
		margin-top: 0;
	}

	&__radio {
		display: flex;
		flex-wrap: wrap;

		&.flex-direction-column {

			label {
				width: 100%;
			}

			> div + div {
				margin: 0;
			}
		}
	}

	&__input {
		display: flex;

		&-list {
			display: flex;
			flex-direction: row;

			.form-input {
				flex: 1 0 auto;
			}

			.Form-input + .Form-input {
				margin-left: 10px;
			}
		}

		&-calendar {
			align-items: flex-end;

			span {
				line-height: 30px;
				margin: 0 30px;
			}

			.time-separator {
				margin: 0 10px;
			}

			.calendar-date {
				flex: auto;
				width: 100%;
			}

			.calendar-time {
				width: 83px;
			}
		}
	}
}