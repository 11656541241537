.btn {
	outline: none;
	text-decoration: none;
	font-family: 'SFProText', sans-serif;
	font-size: 15px;
	font-weight: 600;
	line-height: 1.3;
	letter-spacing: -0.17px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding: 9px 8px 8px;

	&.btn-blue {
		color: #fff;
		background: #4986cc;
	}
	
	&.btn-white {
		color: #397ecc;
		background: #fff;
	}
	
	&.btn-gray {
		color: #397ecc;
		background: #f2f3f5;
	}

	&.btn-transparent {
		color: #ffffff;
		background: rgba(255,255,255,0.2);
	}
}