.ModalOwner {
	position: fixed;
	background-color: rgba(0,0,0,.4);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 2;
}

.Modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	filter: drop-shadow(0 4px 40px rgba(#000, .2));
	z-index: 10;
	width: calc(100% - 16px);
	max-width: 373px;

	&__content {

		> div:last-child {
			border-bottom-left-radius: 12px;
			border-bottom-right-radius: 12px;
		}
	}

	&__download-icon {
		max-width: 450px;

		.btn {
			margin-top: 21px;
		}
	}

	&__feedback {
		max-width: 510px;
		width: 100%;
	}

	&__close {
		//background: url('/img/close.svg') no-repeat;
		width: 12px;
		height: 12px;
		background: #fff;
		cursor: pointer;
	}

	&__header {
		background-color: #ffffff;
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
		font-size: 16px;
		font-weight: 600;
		color: #000;
		letter-spacing: -0.2px;
		padding: 16px 16px 0 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__body {
		padding: 5px 16px 16px 16px;
		background-color: #fff;

		p {
			font-size: 15px;
			line-height: 1.33;
			letter-spacing: -0.24px;
			text-align: left;
			color: #000000;
			margin: 0;
		}
	}

	&__buttons {
		display: flex;
		flex-direction: row;
		margin-top: 15px;

		.btn {
			width: 50%;
		}

		.btn + .btn {
			margin-left: 10px;
		}
	}
}

.modal {
	position: fixed;
	z-index: 33;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.4);
	opacity: 0;
	visibility: hidden;

	&--active {
		opacity: 1;
		visibility: visible;
	}

	&__item {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}

	&__close {
		position: absolute;
		top: 16px;
		right: 16px;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		cursor: pointer;
		background: rgba(0, 28, 61, 0.09);

		&:after {
			content: '';
			width: 24px;
			height: 24px;
			position: absolute;
			background-size: 10px 10px;
			background-repeat: no-repeat;
			background-position: center;
			background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ3Ljk3MSA0Ny45NzEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ3Ljk3MSA0Ny45NzE7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPjxnPjxnPgoJPHBhdGggZD0iTTI4LjIyOCwyMy45ODZMNDcuMDkyLDUuMTIyYzEuMTcyLTEuMTcxLDEuMTcyLTMuMDcxLDAtNC4yNDJjLTEuMTcyLTEuMTcyLTMuMDctMS4xNzItNC4yNDIsMEwyMy45ODYsMTkuNzQ0TDUuMTIxLDAuODggICBjLTEuMTcyLTEuMTcyLTMuMDctMS4xNzItNC4yNDIsMGMtMS4xNzIsMS4xNzEtMS4xNzIsMy4wNzEsMCw0LjI0MmwxOC44NjUsMTguODY0TDAuODc5LDQyLjg1Yy0xLjE3MiwxLjE3MS0xLjE3MiwzLjA3MSwwLDQuMjQyICAgQzEuNDY1LDQ3LjY3NywyLjIzMyw0Ny45NywzLDQ3Ljk3czEuNTM1LTAuMjkzLDIuMTIxLTAuODc5bDE4Ljg2NS0xOC44NjRMNDIuODUsNDcuMDkxYzAuNTg2LDAuNTg2LDEuMzU0LDAuODc5LDIuMTIxLDAuODc5ICAgczEuNTM1LTAuMjkzLDIuMTIxLTAuODc5YzEuMTcyLTEuMTcxLDEuMTcyLTMuMDcxLDAtNC4yNDJMMjguMjI4LDIzLjk4NnoiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJhY3RpdmUtcGF0aCIgZGF0YS1vbGRfY29sb3I9IiMwMDAwMDAiIGZpbGw9IiM4MThDOTkiLz4KPC9nPjwvZz4gPC9zdmc+Cg==');
		}
	}

	&__qr-download {
		max-width: 343px;
		width: calc(100% - 32px);
		border-radius: 14px;
		box-shadow: 0 -1px 16px 0 rgba(0, 0, 0, 0.06);
		background-color: #ffffff;
		display: flex;
		flex-direction: column;

		&--body {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 234px;

			> div {
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			p {
				margin: 17px 0 0;
				padding: 0;
				font-family: 'TTCommons', sans-serif;
				font-size: 28px;
				font-weight: 600;
				line-height: 1.04;
				letter-spacing: normal;
				text-align: center;
				color: #2c2d2e;
			}
		}

		&--footer {
			display: flex;
			flex-direction: column;
			padding: 16px;

			.btn + .btn {
				margin-top: 8px;
			}
		}

		.btn {
			height: 44px;
			min-height: 44px;
			font-size: 17px;
		}
	}
}

.icon-check {
	width: 66px;
	height: 66px;
	border: 3.4px solid #4986cc;
	border-radius: 50%;
	background-color: #fff;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 30px;
	background-image: url("../img/icons/check.svg");
}
