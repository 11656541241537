.form-radio-color {
	position: relative;
	display: flex;
	align-items: center;
	margin-top: 4px;
	
	& + &  {
		margin-left: 4px;
	}
	
	input[type="radio"] {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		cursor: pointer;
		outline: 0 !important;
		
		& + label {
			position: relative;
			width: 40px;
			height: 40px;
			pointer-events: none;
			
			&:before {
				content: '';
				width: 40px;
				height: 40px;
				border-radius: 50%;
				display: inline-block;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				border: 2px solid transparent;
				background-color: #fff;
				opacity: 0;
			}
			
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 32px;
				height: 32px;
				border-radius: 50%;
				cursor: pointer;
				pointer-events: all;
			}
		}
		
		&:checked+label:before {
			opacity: 1;
		}
		
		&:checked+label:before {
			border-color: #5c9ce6;
		}
	}
	
	&__option {
		position: absolute;
		width: 32px;
		height: 32px;
		background: url('../img/icons/colors.svg') no-repeat;
		-webkit-background-size: contain;
		background-size: contain;
		border-radius: 50%;
		z-index: 1;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		
		&:hover {
			cursor: pointer;
			background: rgba(#000, .5);
		}
	}
	
	&.color-white label:after {
		background: #fff;
		border: 1px solid rgba(#000, .08);
	}
	
	&.color-black label:after {
		background: #000;
	}
	
	&.color-blue label:after {
		background: #5181b8;
	}

	&.color-transparent label:after {
		background: url("../img/icons/transparent.png");
		background-size: contain;
	}
	
	&.color-gradient label:after {
		background: url("../img/icons/gradient.jpg");
		background-size: contain;
	}

	&_color-photo {
		position: relative;
		width: 32px;
		height: 32px;
		cursor: pointer;
		border-radius: 50%;
		background: url("../img/icons/photo.svg") center no-repeat;
		background-size: 14px 12px;
		border: 1px solid #5181b8;
		box-sizing: border-box;
		margin-left: 4px;

		&:hover {
			opacity: .85;
		}
	}
}