.white-block {
	background-color: #ebedf0;
	margin-bottom: 10px;

	&-no-margin {
		margin-bottom: 0;
	}

	&__inner.pad-24 {
		padding: 24px;
	}

	&__inner {
		position: relative;
		background-color: #fff;
		padding: 19px 16px 22px;

		&--full {
			align-items: center;
			display: flex;
			justify-content: center;
		}
		
		&:before, &:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			height: 1px;
			background: #dedfe0;
			z-index: 1;
		}
		
		&:before {
			top: 0;
		}
		
		&:after {
			bottom: 0;
		}
	}
	
	.form-input:first-child {
		margin-top: 0;
	}
	
	&__info {
		font-size: 13px;
		line-height: 1.38;
		letter-spacing: -0.1px;
		color: #000000;
		padding-bottom: 10px;
		margin-bottom: 10px;
		border-bottom: 1px solid #e7e8ec;
		
		p {
			margin: 0 0 10px;
			
		}
	}
}