.panel-complete {
  min-height: calc(100vh - 48px - 48px - 48px - 16px);
  background: #fff;
}

.panel-complete .qr-complete {
  height: calc(100vh - 48px - 48px - 48px - 16px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.panel-complete .qr-complete .icon-check {
  width: 44px;
  height: 44px;
  background-size: 20px;
}

.panel-complete .qr-complete .complete-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.34px;
  color: #000000;
  margin-top: 16px;
}

.panel-complete .complete-cell-blocks {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  padding-bottom: 16px;
}
