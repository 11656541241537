.footer-btn {
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #fff;
	border-top: 1px solid #dedfe0;
	padding: 12px 16px;

	& > div {
		width: 50%;
	}

	.btn + .btn {
		margin-left: 10px;
	}
}