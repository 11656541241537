.header {
	padding: 24px 16px;
	box-shadow: 0 1px 0 0 #e7e8ec;
	background-image: linear-gradient(124deg, #4a8fde, #4880c0);
	
	&__title {
		font-family: 'TTCommons', sans-serif;
		font-size: 24px;
		font-weight: 600;
		color: #fff;
		display: flex;
		align-items: center;
		
		span {
			margin-left: 12px;
			margin-bottom: -4px;
		}
	}
	
	.logo {
		background: url('../img/icons/vk_white.svg') 0 0 no-repeat;
		-webkit-background-size: contain;
		background-size: contain;
		width: 31px;
		height: 31px;
	}
	
	&__info {
		margin-top: 22px;
		font-family: 'SFProText', sans-serif;
		font-size: 14px;
		line-height: 1.4;
		color: #fff;
	}
	
	&__btn {
		margin-top: 24px;
		display: flex;
		justify-content: space-between;

		.btn {
			width: calc(50% - 5px);
		}

		.btn + .btn {
			margin-left: 10px;
		}
	}
}